import { styled, Typography } from "@mui/material";
import { ThemeButton } from "../../ui/ThemeComponents/ThemeButton";
import { useClearFilters } from "../../ui/Filter";

const Container = styled("div")(({ theme }) => ({
  marginTop: "2rem",
  marginLeft: "2rem",
  [theme.breakpoints.up("md")]: {
    marginTop: "4rem",
    marginLeft: "4rem",
  },
}));
const StyledButton = styled(ThemeButton)(() => ({
  marginTop: "2rem",
}));

export const NoItems = () => {
  const clearFilters = useClearFilters();

  return (
    <Container>
      <Typography variant="h2">
        Couldn’t find
        <br /> what you wanted?
      </Typography>
      <Typography variant="body1">
        Please change the search criteria or filter.
      </Typography>
      <StyledButton onClick={clearFilters}>Clear filters</StyledButton>
    </Container>
  );
};
