import type { FunctionComponent } from "react";
import type { Size } from "../../types/theme";
import type { PublicComponentProps } from "./PublicComponentProps";
import UIHeader from "../ui/Header";

export interface Settings {
  text: string;
  size: Size["size"];
}

const Header: FunctionComponent<PublicComponentProps<Settings>> = ({
  settings: { size, text },
}) => <UIHeader size={size}>{text}</UIHeader>;

export default Header;
