import React from "react";
import { styled } from "@mui/material";
import { formatToCurrency } from "../../helpers/formatter";

interface Props {
  // TODO: unify averageTotalSavingsPrefix
  averageTotalSavingsPrefix: "up_to" | "exact";
  averageTotalSavingsAmount: number;
  color?: string;
}
const Content = styled("span")<Pick<Props, "color">>(({ theme, color }) => ({
  color: color || theme.palette.success.main,
}));

const EarningsBox: React.FunctionComponent<Props> = ({
  color,
  averageTotalSavingsAmount,
  averageTotalSavingsPrefix,
}) =>
  averageTotalSavingsAmount ? (
    <Content color={color}>
      Save {averageTotalSavingsPrefix === "up_to" && "up to "}
      {formatToCurrency(averageTotalSavingsAmount)}
    </Content>
  ) : (
    <></>
  );

export default EarningsBox;
