import { APIV3 } from "../HTTPClientV3";

export interface ManagerJwtResponse {
  jwt?: string;
}

const base = `/managers`;

export async function getManagerJwtAuth() {
  const response = await APIV3.get<ManagerJwtResponse>(`${base}/jwt`);
  return response.json();
}

export async function getManagerJwtAuthAndBecomeSeller() {
  const response = await APIV3.post<ManagerJwtResponse>(
    `${base}/jwt/become_seller`,
  );
  return response.json();
}

export async function acceptInvitation() {
  const response = await APIV3.put<ManagerJwtResponse>(`${base}/invitations`);
  return response.json();
}
