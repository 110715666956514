import { Fragment, FunctionComponent } from "react";
import { styled } from "@mui/material";
import { toXCase } from "../../helpers/formatter";

interface Props {
  type: "component" | "property";
  children: string;
}

const ComponentLabel = styled("span")(() => ({
  textTransform: "capitalize",
}));

export function toPropertyName(property: string) {
  const name = toXCase(property, "case with spaces") || "";
  return name.charAt(0).toUpperCase() + name.slice(1);
}

export const FieldLabelFormatter: FunctionComponent<Props> = ({
  type,
  children,
}) => {
  const Wrapper = type === "component" ? ComponentLabel : Fragment;
  // TODO: Once metadata is totally filled in al components, review and remove unneded code here
  const label = toPropertyName(children);
  return <Wrapper>{label}</Wrapper>;
};
