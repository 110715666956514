import { FunctionComponent, PropsWithChildren, ReactNode } from "react";
import { Typography, alpha, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import BuilfirstLogo from "../../assets/svg/builtfirst-logo-white.svg";

interface Props {
  children?: ReactNode;
  renderActions?: () => ReactNode;
  onClose: () => void;
}

const StyledFlexContainer = styled("div")(() => ({
  display: "flex",
  alignItems: "center",
}));

const StyledContainer = styled("div")(({ theme }) => ({
  minHeight: "4.3rem",
  width: "100%",
  position: "fixed",
  paddingLeft: theme.spacing(10),
  paddingRight: theme.spacing(10),
  bottom: 0,
  backgroundColor: alpha("#6573C8", 0.75),
  zIndex: 5000,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  color: "#fff",
}));

const StyledActionsContainer = styled(StyledFlexContainer)(({ theme }) => ({
  "& > button": {
    borderColor: "#fff",
    color: "#fff",
    marginRight: theme.spacing(3),
    "&:hover": {
      borderColor: "#fff",
    },
  },
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: "#fff",
  marginLeft: theme.spacing(3),
}));

const StyledCloseIcon = styled(CloseIcon)(() => ({
  cursor: "pointer",
}));

const FooterBanner: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  renderActions,
  onClose,
}) => (
  <StyledContainer>
    <StyledFlexContainer>
      <BuilfirstLogo />
      <StyledTypography>{children}</StyledTypography>
    </StyledFlexContainer>
    <StyledActionsContainer>
      {renderActions && renderActions()}
      <StyledCloseIcon onClick={onClose} />
    </StyledActionsContainer>
  </StyledContainer>
);

export default FooterBanner;
