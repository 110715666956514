import { FunctionComponent } from "react";
import { LayoutComponent } from "../../builder/Layout";
import type { Settings as HeaderSettings } from "./Header";
import type { Settings as TextSettings } from "./Text";
import type { Settings as ManageProfileSignUpButton } from "./ManageProfileSignUpButton";
import { Settings } from "./BannerWithTextWithImage";
import SimpleBannerContent from "../ui/SimpleBannerContent";

interface BannerManageProfileSignUpSubComponents {
  Header?: LayoutComponent<HeaderSettings>;
  Text?: LayoutComponent<TextSettings>;
  ManageProfileSignUpButton?: LayoutComponent<ManageProfileSignUpButton>;
}

interface Props {
  settings: Settings;
  subComponents: BannerManageProfileSignUpSubComponents;
}

const BannerManageProfileSignUp: FunctionComponent<Props> = ({
  settings: { justification, layout },
  subComponents,
}) => (
  <SimpleBannerContent
    elements={Object.values(subComponents)}
    justification={justification}
    layout={layout}
  />
);

export default BannerManageProfileSignUp;
