import { FunctionComponent, ReactNode, useMemo, useState } from "react";
import { styled } from "@mui/material";
import { useRouter } from "next/router";
import { usePagesSettings } from "../../../builder/Theme";
import { PartnerResponse } from "../../../dataAccess/api/partners";
import { CategoryResponse } from "../../../dataAccess/api/categories";
import { CollectionHeader } from "../../../dataAccess/api/collections";

import { useQueryParams } from "../../../dataAccess/QueryParams";
import { PaginationVariant } from "../../ui/Pagination";
import { getSections } from "../../../dataAccess/api/items";
import TileSection, { TileSectionProps } from "../../ui/TileSection";
import { LayoutComponent } from "../../../builder/Layout";
import type { Settings as SearchSettings } from "../Search";
import type { Settings as FilterSettings } from "../PartnersFilter";
import { Alignment, Position, Size } from "../../../types/theme";
import { NoItems } from "./NoItems";
import { Container } from "../../ui/DefaultPageContainer";

export interface GridSubComponents {
  Search?: LayoutComponent<SearchSettings>;
  CollectionsFilter?: LayoutComponent<FilterSettings>;
  CategoriesFilter?: LayoutComponent<FilterSettings>;
  PartnersFilter?: LayoutComponent<FilterSettings>;
}

export interface Section {
  type:
    | "collection"
    | "category"
    | "newest"
    | "featured"
    | "seller"
    | "most_popular"
    | "all";
  id?: number | null;
  title: string;
}

export interface GridColumnsSettings {
  maxNumberOfColumnsOnDesktop: 1 | 2 | 3 | 4 | 5 | 6;
  maxNumberOfColumnsOnTablet: 1 | 2 | 3 | 4;
  maxNumberOfColumnsOnMobile: 1 | 2;
}

export interface GridSettings extends GridColumnsSettings {
  // TODO: add missing configuration
  filtersAligment: Exclude<Alignment, "center">;
  sectionTileDensity: TileSectionProps["sectionTileDensity"];
  // This is a wyswyg  property
  showSectionsBy: Section[];
  sectionHeaderSize: Size["size"];
  showSubHeaders: boolean;
  showClearAll: boolean;
  verticalSpacingSectionHeader: number;
  paginationVariant: PaginationVariant;
  filterMenuSize: Size["size"];
  allSectionTitle: string;
  featuredSectionTitle: string;
  mostPopularSectionTittle: string;
  newestSectionTitle: string;
}

interface Props {
  settings: GridSettings;
  subComponents: GridSubComponents;
  partnerList?: PartnerResponse;
  categories?: CategoryResponse;
  collections?: CollectionHeader[];
}

const Main = styled("div")(({ theme }) => ({
  marginTop: "1rem",
  height: "100%",
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  [theme.breakpoints.up("md")]: {
    paddingLeft: theme.defaultSpacing.md.page.edges,
    paddingRight: theme.defaultSpacing.md.page.edges,
  },
  gap: theme.defaultSpacing.md.page.content,
}));

const Horizontal = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.defaultSpacing.md.page.content,
}));

const Content = styled("div")<{
  alignment: Alignment;
}>(({ alignment = "left", theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  gap: theme.defaultSpacing.md.page.content,
  [theme.breakpoints.up("md")]: {
    flexDirection: alignment === "left" ? "row" : "row-reverse",
  },
}));

const getFilterMenuWidth = (size: Size["size"]) => {
  switch (size) {
    case "extraSmall":
      return "12rem";
    case "small":
      return "15rem";
    case "medium":
      return "20rem";
    case "large":
      return "25rem";
    case "extraLarge":
      return "27rem";
    default:
      return "20rem";
  }
};
const Vertical = styled("div")<{ filterMenuSize: Size["size"] }>(
  ({ theme, filterMenuSize }) => ({
    gap: theme.defaultSpacing.md.page.content,
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      width: getFilterMenuWidth(filterMenuSize),
      minWidth: getFilterMenuWidth(filterMenuSize),
      position: "sticky",
      top: "1rem",
      height: "100%",
    },
  }),
);

const Body = styled("div")<{
  filterMenuSize: Size["size"];
}>(({ theme, filterMenuSize }) => ({
  flex: 1,
  display: "flex",
  padding: "0",
  flexDirection: "column",
  alignItems: "stretch",
  gap: theme.defaultSpacing.md.page.content,
  [theme.breakpoints.up("md")]: {
    width: `calc(100% - ${getFilterMenuWidth(filterMenuSize)})`,
  },
}));

const TopSection = styled("div")(({ theme }) => ({
  display: "flex",
  gap: theme.defaultSpacing.md.page.content,
  flexDirection: "column",
}));

const BottomSection = styled("div")(() => ({
  flex: 1,
}));

export const defaultDealPagination = {
  currentPage: 1,
  itemsPerPage: 50,
  totalPages: 0,
  totalItems: 0,
  data: [],
};

function componentExists(component?: ReactNode) {
  return !!component;
}

export function getSectionTileDensity(maxNumberOfColumnsOnDesktop?: number) {
  const numberOfColumns = maxNumberOfColumnsOnDesktop || 4;
  return {
    extraSmall: 1 * numberOfColumns,
    small: 3 * numberOfColumns,
    medium: 6 * numberOfColumns,
    large: 12 * numberOfColumns,
    extraLarge: 24 * numberOfColumns,
  };
}

export function getTilesPerSection(
  sectionTileDensity: Size["size"],
  maxNumberOfColumnsOnDesktop?: number,
) {
  const sectionTileDensities = getSectionTileDensity(
    maxNumberOfColumnsOnDesktop,
  );
  return (
    sectionTileDensities[sectionTileDensity] || sectionTileDensities.extraSmall
  );
}

export const Grid: FunctionComponent<Props> = ({
  subComponents,
  settings,
  partnerList,
  collections,
  categories,
}: Props) => {
  const { q, category_ids, collection_ids, partner_ids } = useQueryParams();
  const router = useRouter();
  const generateSubcomponentsByPosition = (position: Position) =>
    Object.values(subComponents).map(
      (value: GridSubComponents[keyof GridSubComponents]) => {
        if (value?.settings.position === position) {
          return value.component;
        }
      },
    );

  const verticalComponents = useMemo(
    () => generateSubcomponentsByPosition("vertical"),
    [settings],
  );

  const aboveComponents = useMemo(
    () => generateSubcomponentsByPosition("above"),
    [settings],
  );

  const horizontalComponents = useMemo(
    () => generateSubcomponentsByPosition("horizontal"),
    [settings],
  );

  const checkIfItemIdExistsAndAddName = (
    current: SectionEntity,
    items: SectionEntity[],
    idsSet?: Set<string>,
  ) => {
    if (idsSet?.has(String(current.id))) {
      items.push(current);
    }
  };

  type SectionEntity = { id: string; name: string; description?: string };

  const getSectionTitle = (section: Section) => {
    const selectedPartners: SectionEntity[] = [];
    const selectedCategories: SectionEntity[] = [];
    const selectedCollections: SectionEntity[] = [];
    const searchQueryTitle: string[] = q ? [`"${q}"`] : [];
    const { id: sectionId, type } = section;
    let currentSectionEntity: Partial<SectionEntity> = {};

    const partnerIdsSet = new Set(partner_ids?.split(","));
    partnerList?.forEach(({ id, name }) => {
      checkIfItemIdExistsAndAddName(
        {
          id: String(id),
          name,
        },
        selectedPartners,
        partnerIdsSet,
      );
    });

    const categoriesIdsSet = new Set(category_ids?.split(","));
    categories?.forEach(({ id, name }) => {
      checkIfItemIdExistsAndAddName(
        {
          id: String(id),
          name,
        },
        selectedCategories,
        categoriesIdsSet,
      );
    });

    const collectionIdsSet = new Set(collection_ids?.split(","));

    collections?.forEach(({ collections: childCollections }) => {
      childCollections?.forEach(({ id, name, description }) => {
        const currentEntity = {
          id: String(id),
          name,
          description,
        };
        if (String(sectionId) === String(id)) {
          currentSectionEntity = currentEntity;
        }
        checkIfItemIdExistsAndAddName(
          currentEntity,
          selectedCollections,
          collectionIdsSet,
        );
      });
    });

    let title = currentSectionEntity.id
      ? currentSectionEntity.name
      : section.title;

    switch (type) {
      case "all":
        title = settings.allSectionTitle || title;
        break;
      case "featured":
        title = settings.featuredSectionTitle || title;
        break;
      case "most_popular":
        title = settings.mostPopularSectionTittle || title;
        break;
      case "newest":
        title = settings.newestSectionTitle || title;
        break;
      default:
        break;
    }

    const selectedFilterEntities = [
      ...selectedPartners,
      ...selectedCategories,
      ...selectedCollections,
    ];

    const allSelectedFilters = [
      ...searchQueryTitle,
      ...selectedFilterEntities.map(({ name }) => name),
    ].map((filter) => filter.replace(/,/g, ", "));

    const showDescription = allSelectedFilters.length < 2;
    const [firstSelectedFilterEntity] = selectedFilterEntities;
    const entity = currentSectionEntity.id
      ? currentSectionEntity
      : firstSelectedFilterEntity;

    const description = showDescription && entity ? entity.description : "";

    if (allSelectedFilters.length === 0) {
      return { title: title || "", description, clearAll: false };
    }

    const resultsTitleMap = [
      allSelectedFilters[0],
      `${allSelectedFilters[0]} and ${allSelectedFilters[1]}`,
      `${allSelectedFilters[0]}, ${allSelectedFilters[1]} and ${allSelectedFilters[2]}`,
      `${allSelectedFilters[0]}, ${allSelectedFilters[1]} and ${
        allSelectedFilters?.length - 2
      } Other${allSelectedFilters?.length - 3 !== 1 ? "s" : ""}`,
    ];

    const searchResultsTitle =
      allSelectedFilters?.length > 3
        ? `${title} ${resultsTitleMap[3]}`
        : `${title} ${resultsTitleMap[allSelectedFilters.length - 1]}`;

    return {
      title: `${searchResultsTitle}`,
      description,
      clearAll: true,
    };
  };

  const [hasRequestSucceedWithNoData, setHasRequestSucceedWithNoData] =
    useState<boolean[]>([false]);

  const pagesSettings = usePagesSettings().gridHeaders;

  const sections = useMemo(() => {
    const communitySections = getSections(router.query, pagesSettings);
    if (
      communitySections.length === hasRequestSucceedWithNoData.length &&
      hasRequestSucceedWithNoData.every((el) => el === true) &&
      !(category_ids || collection_ids || partner_ids || q)
    ) {
      const baseSection: Section = {
        type: "all",
        title: "All",
      };
      return [baseSection];
    }

    return communitySections;
  }, [router.query, hasRequestSucceedWithNoData]);

  const [sectionsLoading, setSectionsLoading] = useState<boolean[]>(
    Array(sections.length).fill(true),
  );
  const loading = sectionsLoading.includes(true);

  const [sectionsWithNoData, setSectionsWithNoData] = useState<boolean[]>(
    Array(sections.length).fill(true),
  );
  const noData = sectionsWithNoData.every((el) => el === true);

  /*
    Use memo is important here so that the tileSection components are not re-painted
    when the loadingCallback and noDataCallback are executed
  */
  const tileSections = useMemo(
    () =>
      sections?.map((section, idx) => {
        const { title, description, clearAll } = getSectionTitle(section);
        return (
          <TileSection
            key={idx}
            hasRequestSucceedWithNoData={hasRequestSucceedWithNoData}
            section={{ ...section, title }}
            subTitle={description}
            verticalSpacingSectionHeader={settings.verticalSpacingSectionHeader}
            maxNumberOfColumnsOnDesktop={settings.maxNumberOfColumnsOnDesktop}
            maxNumberOfColumnsOnMobile={settings.maxNumberOfColumnsOnMobile}
            maxNumberOfColumnsOnTablet={settings.maxNumberOfColumnsOnTablet}
            headerSize={settings.sectionHeaderSize}
            sectionTileDensity={settings.sectionTileDensity}
            showClearAll={settings.showClearAll && clearAll}
            showSubHeader={settings.showSubHeaders}
            paginationVariant={settings.paginationVariant}
            setHasRequestSucceedWithNoData={(loading) => {
              setHasRequestSucceedWithNoData((prev: boolean[]) => {
                const newState = [...prev];
                newState[idx] = loading;
                return newState;
              });
            }}
            loadingCallback={(loading) => {
              setSectionsLoading((prev: boolean[]) => {
                const newState = [...prev];
                newState[idx] = loading;
                return newState;
              });
            }}
            noDataCallback={(loading) => {
              setSectionsWithNoData((prev: boolean[]) => {
                const newState = [...prev];
                newState[idx] = loading;
                return newState;
              });
            }}
          />
        );
      }),
    [sections],
  );
  const displayAboveComponents = aboveComponents.find(componentExists);
  const displayHorizontalComponents =
    horizontalComponents.find(componentExists);
  const displayVerticalComponents = verticalComponents.find(componentExists);

  return (
    <Container>
      <Main>
        {displayAboveComponents && <Horizontal>{aboveComponents}</Horizontal>}
        <Content alignment={settings?.filtersAligment}>
          {displayVerticalComponents && (
            <Vertical filterMenuSize={settings.filterMenuSize}>
              {verticalComponents}
            </Vertical>
          )}
          <Body filterMenuSize={settings.filterMenuSize}>
            {displayHorizontalComponents && (
              <TopSection>{horizontalComponents}</TopSection>
            )}
            <BottomSection>
              {tileSections}
              {!loading && noData ? <NoItems /> : null}
            </BottomSection>
          </Body>
        </Content>
      </Main>
    </Container>
  );
};

export default Grid;
