import { FunctionComponent } from "react";
import { styled } from "@mui/material";
import Banner, { BannerConfiguration } from "../ui/Banner";
import { Container } from "../ui/DefaultPageContainer";
import { PublicComponentProps } from "./PublicComponentProps";
import {
  MediaSetting,
  getJustificationByAlignment,
} from "../../builder/themeOptionsBuilder";
import type { NavigationComponentProps } from "../pages/PDP";
import PageNavigation from "../ui/PageNavigation";

const StyledRootContainer = styled(Banner)(({ theme }) => {
  const { [theme.breakpoints.up("lg")]: lg, ...medias } =
    theme.getMediaForSettings(() => ({
      paddingTop: MediaSetting.top,
      paddingBottom: MediaSetting.top,
    }));
  return {
    paddingTop: theme.settings.layout?.mobile.top,
    paddingBottom: theme.settings.layout?.mobile.top, // this should be top because both paddings should be the same
    padding: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      paddingLeft: theme.defaultSpacing.md.page.edges,
      paddingRight: theme.defaultSpacing.md.page.edges,
      ...lg,
    },
    ...medias,
  };
});

interface Props
  extends PublicComponentProps<BannerConfiguration>,
    NavigationComponentProps {
  homePageLogo?: string;
  marketplaceName?: string;
}

const FallbackContainer = styled(Container)(() => ({
  maxWidth: "initial !important",
}));

const ContentContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(2),
  marginLeft: theme.defaultSpacing.md.page.edges,
  marginRight: theme.defaultSpacing.md.page.edges,
}));

const Content = styled("div")<
  Pick<BannerConfiguration["layout"], "layoutAlignment" | "minHeight">
>(({ theme, layoutAlignment, minHeight }) => ({
  display: "inline-flex",
  flex: 1,
  justifyContent: getJustificationByAlignment(layoutAlignment),
  alignItems: "center",
  minHeight,
  gap: theme.spacing(2),
  "& img": {
    maxWidth: 250,
  },
}));

const BannerWithLogo: FunctionComponent<Props> = ({
  settings: { layout },
  homePageLogo,
  marketplaceName,
  backButtonIn,
  navigation,
  item,
  isBackButtonIn,
}) => {
  const Wrapper = layout.contained ? Container : FallbackContainer;
  const showNavigation = !!(
    isBackButtonIn && backButtonIn === "BannerWithLogo"
  );
  return (
    <StyledRootContainer layout={layout}>
      <Wrapper>
        <ContentContainer>
          {showNavigation && (
            <PageNavigation
              navigation={{
                type: navigation,
                links: [{ label: item?.name || "" }],
              }}
              textColor={layout.textColor}
            />
          )}
          <Content
            minHeight={layout.minHeight}
            layoutAlignment={layout.layoutAlignment}
          >
            <img
              alt={`${marketplaceName} Logo`}
              src={homePageLogo || "default-home-logo.svg"}
            />
          </Content>
        </ContentContainer>
      </Wrapper>
    </StyledRootContainer>
  );
};

export default BannerWithLogo;
