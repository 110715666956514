import { FunctionComponent } from "react";
import { LayoutComponent } from "../../builder/Layout";
import type { Settings as HeaderSettings } from "./Header";
import type { Settings as TextSettings } from "./Text";
import type { Settings as CTAButtonSettings } from "./CTAButton";
import { BannerConfiguration } from "../ui/Banner";
import SimpleBannerContent from "../ui/SimpleBannerContent";
import { Alignment } from "../../types/theme";

export interface Settings extends BannerConfiguration {
  justification: Alignment;
}

interface BannerWithTextWithImageSubComponents {
  Header?: LayoutComponent<HeaderSettings>;
  Text?: LayoutComponent<TextSettings>;
  CTAButton?: LayoutComponent<CTAButtonSettings>;
}

interface Props {
  settings: Settings;
  subComponents: BannerWithTextWithImageSubComponents;
}

const BannerWithTextWithImage: FunctionComponent<Props> = ({
  settings: { justification, layout },
  subComponents,
}) => (
  <SimpleBannerContent
    elements={Object.values(subComponents)}
    justification={justification}
    layout={layout}
  />
);

export default BannerWithTextWithImage;
