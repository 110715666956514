import { useEffect, useMemo } from "react";
import { styled } from "@mui/material";
import { GridColumnsSettings } from "../../public/Grid";
import { Item, useItems } from "../../../dataAccess/api/items";
import Tile from "../Tile";
import { PaginationMeta } from "../../../dataAccess/HTTPClientV3";
import { tileGap, TileSectionProps } from ".";

function calcTileWidth(maxNumberOfColumns: number) {
  return `calc(${100 / maxNumberOfColumns}% - ${
    (tileGap * (100 - 100 / maxNumberOfColumns)) / 100
  }rem)`;
}

const TileContainer = styled("div")<GridColumnsSettings>(
  ({
    theme,
    maxNumberOfColumnsOnMobile,
    maxNumberOfColumnsOnTablet,
    maxNumberOfColumnsOnDesktop,
  }) => ({
    width: calcTileWidth(maxNumberOfColumnsOnMobile),
    [theme.breakpoints.up("sm")]: {
      width: calcTileWidth(maxNumberOfColumnsOnTablet),
    },
    [theme.breakpoints.up("lg")]: {
      width: calcTileWidth(maxNumberOfColumnsOnDesktop),
    },
  }),
);

interface TileSectionPageProps extends GridColumnsSettings {
  page: number;
  section: TileSectionProps["section"];
  loadingCallback: (loading: boolean) => void;
  setHasRequestSucceedWithNoData: (isSuccess: boolean) => void;
  dataAvailableCallback: (dataAvailable: boolean) => void;
  sectionPageMetaCallback: (pageMeta: PaginationMeta) => void;
  sectionAllPageMetaCallback: (pageMeta: PaginationMeta) => void;
  pageSize: number;
}

export const TileSectionPage = ({
  page,
  section,
  loadingCallback,
  dataAvailableCallback,
  sectionPageMetaCallback,
  sectionAllPageMetaCallback,
  setHasRequestSucceedWithNoData,
  pageSize,
  maxNumberOfColumnsOnDesktop,
  maxNumberOfColumnsOnMobile,
  maxNumberOfColumnsOnTablet,
}: TileSectionPageProps) => {
  const { itemsList: items, loadingItems } = useItems(
    section,
    page,
    pageSize,
    maxNumberOfColumnsOnDesktop,
  );

  useEffect(() => loadingCallback(loadingItems), [loadingItems]);
  useEffect(() => {
    dataAvailableCallback(!!(items && items.data && items.data.length));

    if (items) {
      if (items.data.length === 0) {
        setHasRequestSucceedWithNoData(true);
      } else if (section.type !== "all") {
        setHasRequestSucceedWithNoData(false);
      }
      sectionPageMetaCallback(items.meta);
      if (section.type === "all") {
        sectionAllPageMetaCallback(items.meta);
      }
    }
  }, [items]);

  const loadingMockData: Item[] = useMemo(
    () =>
      new Array(
        section.type === "all" ? maxNumberOfColumnsOnDesktop * 12 : pageSize,
      ).fill({
        id: 0,
        name: "",
        description: "",
      }),
    [pageSize, maxNumberOfColumnsOnDesktop, section.type],
  );

  if (!items && !loadingItems) {
    return null;
  }
  const data = loadingItems ? loadingMockData : items?.data;
  return (
    <>
      {data?.map((item, idx) => (
        <TileContainer
          key={idx}
          maxNumberOfColumnsOnDesktop={maxNumberOfColumnsOnDesktop}
          maxNumberOfColumnsOnMobile={maxNumberOfColumnsOnMobile}
          maxNumberOfColumnsOnTablet={maxNumberOfColumnsOnTablet}
        >
          <Tile item={item} loading={!!loadingItems} />
        </TileContainer>
      ))}
    </>
  );
};
