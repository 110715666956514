import { FunctionComponent } from "react";
import { Typography } from "@mui/material";
import { PublicComponentProps } from "./PublicComponentProps";

export interface Settings {
  text: string;
}

const Text: FunctionComponent<PublicComponentProps<Settings>> = ({
  settings: { text },
}) => <Typography>{text}</Typography>;

export default Text;
