import { useMemo } from "react";
import type { ComponentType } from "../wysiwyg/JsonThemeObject";
import {
  Configuration,
  useConfiguration,
} from "../dataAccess/api/configuration";
import { profile } from "../wysiwyg/schemas/components/profile";
import { useEditor } from "../wysiwyg/EditorCtx";

function isMarketplaceAccountsWithoutJWT(configuration?: Configuration) {
  return configuration?.forceAccounts && !configuration.ssoSetting.forceJwtAuth;
}

export function useMarketplaceAccountsWithoutJWT() {
  const { configuration } = useConfiguration();
  return isMarketplaceAccountsWithoutJWT(configuration);
}

export function useMarketplaceAccountsWithoutJWTOnSpecificPages() {
  const marketplaceAccountsWithoutJWT = useMarketplaceAccountsWithoutJWT();
  const { editorValue } = useEditor();
  return marketplaceAccountsWithoutJWT && editorValue?.includes("PDP");
}

export function useMarketplaceNoAccounts() {
  const { configuration } = useConfiguration();
  return !configuration?.forceAccounts;
}

export function forceIncludeComponents(configuration: Configuration) {
  const componentsToInclude: ComponentType[] = [];
  if (isMarketplaceAccountsWithoutJWT(configuration)) {
    componentsToInclude.push("Profile");
  }
  componentsToInclude.forEach((componentType) => {
    if (componentType === "Profile") {
      configuration.publishedTemplate?.properties.pages.forEach((page) => {
        if (
          ["PDP"].includes(page.id) &&
          page.components &&
          !page.components.find(({ type }) => componentType === type)
        ) {
          page.components.unshift({
            type: componentType,
            uniqueId: "",
            settings: { ...(profile.settings || {}) },
          });
        }
      });
    }
  });

  const { pages } = configuration.publishedTemplate?.properties || {};
  const plp = pages?.find(({ id }) => id === "PLP");
  const grid = plp?.components?.find(({ type }) => type === "Grid");
  const gridSettings = grid?.settings;

  if (gridSettings) {
    gridSettings.showSectionsBy = gridSettings?.showSectionsBy?.length
      ? gridSettings.showSectionsBy
      : [
          { type: "featured", id: null, title: "Featured" },
          { type: "all", id: null, title: "All" },
        ];
  }
  return configuration;
}

export function useComponentsToForceExclude() {
  const marketplaceNoAccounts = useMarketplaceNoAccounts();
  const componentsToForceExclude: ComponentType[] = useMemo(() => {
    const components: ComponentType[] = [];
    if (marketplaceNoAccounts) {
      components.push("Profile");
      components.push("Quote");
    }
    return components;
  }, [marketplaceNoAccounts]);
  return { componentsToForceExclude };
}
