import { FunctionComponent } from "react";
import { styled, useTheme } from "@mui/material";
import {
  StandardHeader,
  Props as BaseTileHeaderProps,
  tileLogoMargin,
} from "./StandardTileHeader";
import {
  StackedDealName,
  StackedSavingAmount,
  StackedPartnerName,
} from "./StackedTileHeader";
import EarningsBox from "../EarningsBox";
import TileLogo from "./TileLogo";
import { getTileSpacing } from ".";

const CompactHeader = styled(StandardHeader)(({ theme }) => ({
  flexWrap: "nowrap",
  marginBottom: getTileSpacing(theme),
  paddingTop: theme.settings.tiles.padding
    ? theme.typography.pxToRem(theme.settings.tiles.padding)
    : "0.8rem",
  paddingLeft: theme.settings.tiles.padding
    ? theme.typography.pxToRem(theme.settings.tiles.padding)
    : "0.8rem",
  paddingRight: theme.settings.tiles.padding
    ? theme.typography.pxToRem(theme.settings.tiles.padding)
    : "0.8rem",
}));

const TileLogoContainer = styled("div")(() => ({
  marginRight: tileLogoMargin,
}));

const logoSize = 50;
const RightSection = styled("div")(() => ({
  maxWidth: `calc(100% - ${logoSize}px - ${tileLogoMargin})`,
}));

const PartnerName = styled(StackedPartnerName)(() => ({
  margin: 0,
}));

const DealName = styled(StackedDealName)(() => ({
  margin: 0,
}));

const CompactTileHeader: FunctionComponent<BaseTileHeaderProps> = ({
  item: { name, logoUrl = "", managerOrganization, deal },
}: BaseTileHeaderProps) => {
  const theme = useTheme();
  return (
    <CompactHeader>
      {logoUrl && (
        <TileLogoContainer>
          <TileLogo
            logoUrl={logoUrl}
            orgName={managerOrganization.name}
            width={50}
            height={50}
          />
        </TileLogoContainer>
      )}
      <RightSection>
        {theme.settings.tiles.titleContent.showVendorName && (
          <PartnerName variant="h5" noWrap title={managerOrganization.name}>
            {managerOrganization.name}
          </PartnerName>
        )}
        <DealName variant="h5" title={name}>
          {theme.settings.tiles.titleContent.showListingName
            ? name
            : deal?.title}
        </DealName>

        {theme.settings.tiles.titleContent.showSavingAmount &&
          !!deal?.averageTotalSavingsAmount && (
            <StackedSavingAmount>
              <EarningsBox
                color={theme.palette.tileOthersColor.main}
                averageTotalSavingsAmount={deal.averageTotalSavingsAmount}
                averageTotalSavingsPrefix={deal.averageTotalSavingsPrefix}
              />
            </StackedSavingAmount>
          )}
      </RightSection>
    </CompactHeader>
  );
};

export default CompactTileHeader;
