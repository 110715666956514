import { Fragment, FunctionComponent, useMemo } from "react";
import { styled } from "@mui/material";
import { LayoutComponent } from "../../builder/Layout";
import Banner, { BannerConfiguration } from "./Banner";
import { Container } from "./DefaultPageContainer";
import { Alignment } from "../../types/theme";

interface Props {
  justification: Alignment;
  layout: BannerConfiguration["layout"];
  elements: LayoutComponent<unknown>[];
}

const Main = styled(Banner)(({ layout }) => ({
  padding: "1rem",
  display: "flex",
  maxWidth: "100%",
  ".MuiTypography-root": {
    ...(layout.textColor
      ? {
          color: `${layout.textColor} !important`,
        }
      : {}),
  },
}));

const ContentContainer = styled(Container)(() => ({
  display: "inherit",
  justifyContent: "inherit",
}));

const ComponentsContainer = styled("div")<{ contained: boolean }>(
  ({ theme, contained }) => ({
    display: "flex",
    gap: theme.spacing(0.5),
    flexDirection: "column",
    padding: theme.spacing(0),
    ...(!contained
      ? {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        }
      : {}),
  }),
);

const ComponentContainer = styled("div")<{
  justification: Alignment;
}>(({ justification }) => ({
  textAlign: justification,
  marginBottom: "0.5rem",
}));

const SimpleBannerContent: FunctionComponent<Props> = ({
  justification,
  layout,
  elements,
}) => {
  const Wrapper = layout.contained ? Container : Fragment;
  const elementValues = useMemo(
    () => elements.filter((component) => component),
    [elements],
  );
  return (
    <Wrapper>
      <Main layout={layout}>
        <ContentContainer>
          <ComponentsContainer contained={!!layout.contained}>
            {elementValues.map(({ component }, index) => (
              <ComponentContainer key={index} justification={justification}>
                {component}
              </ComponentContainer>
            ))}
          </ComponentsContainer>
        </ContentContainer>
      </Main>
    </Wrapper>
  );
};

export default SimpleBannerContent;
