import Head from "next/head";
import { GetServerSidePropsContext } from "next";
import { styled } from "@mui/material";
import { useEffect } from "react";
import { useQueryParams } from "../../dataAccess/QueryParams";
import { useAnalyticsTracker } from "../../app/AnalyticsTracker";
import CommonHead from "../../builder/CommonHead";
import { PageSettings } from "../../builder/PageConfiguration";
import { getComponentSettings, getGridHeaders } from "../../builder/Theme";
import {
  getCollections,
  useCollections,
} from "../../dataAccess/api/collections";
import { getPartners, usePartners } from "../../dataAccess/api/partners";
import { getCategories, useCategories } from "../../dataAccess/api/categories";
import {
  Configuration,
  useConfiguration,
} from "../../dataAccess/api/configuration";
import { Layout } from "../../builder/Layout";
import { getItems, getSections } from "../../dataAccess/api/items";
import BannerWithLogo from "../public/BannerWithLogo";
import BannerWithTextCustomHTML from "../public/BannerWithTextCustomHTML";
import BannerManageProfileSignUp from "../public/BannerManageProfileSignUp";
import BannerWithTextWithImage from "../public/BannerWithTextWithImage";
import CTAButton from "../public/CTAButton";
import Header from "../public/Header";
import Text from "../public/Text";
import Search from "../public/Search";
import ManageProfileSignUpButton from "../public/ManageProfileSignUpButton";
import CollectionsFilter from "../public/CollectionsFilter";
import PartnersFilter from "../public/PartnersFilter";
import CategoriesFilter from "../public/CategoriesFilter";
import { Grid, GridSettings, getTilesPerSection } from "../public/Grid";
import Profile, { ProfileContext, useProfileState } from "../public/Profile";
import { useScreenshotPreviewElementEffect } from "../../helpers/environment";
import PoweredByBuiltfirstBanner from "../ui/PoweredByBuiltfirstBanner";

export const getServerSideProps = async (
  context: GetServerSidePropsContext,
  configuration: Configuration,
) => {
  const services = [getPartners(), getCollections(), getCategories()];
  const gridSettings = getComponentSettings<GridSettings>(
    configuration,
    "PLP",
    "Grid",
  );
  getSections(context.query, getGridHeaders(configuration)).forEach(
    (section, index) =>
      services.push(
        getItems(
          context.query,
          section,
          index + 1,
          getTilesPerSection(
            gridSettings.sectionTileDensity,
            gridSettings.maxNumberOfColumnsOnDesktop,
          ),
        ),
      ),
  );

  const results = await Promise.all(services.map((service) => service.json()));
  const fallback: any = {};
  results.forEach((data, index) => {
    fallback[services[index].fallbackKey] = data;
  });

  return {
    props: {
      fallback,
    },
  };
};

/**
 * this imports are not dynamic to reduce bundle number of files:
 */
export const availableComponents = {
  Grid,
  BannerWithLogo,
  BannerWithTextCustomHTML,
  ManageProfileSignUpButton,
  BannerWithTextWithImage,
  BannerManageProfileSignUp,
  CTAButton,
  Header,
  Text,
  Search,
  PartnersFilter,
  CategoriesFilter,
  CollectionsFilter,
  Profile,
};

export type AvailableComponents = typeof availableComponents;

export const MainContainer = styled("div")(() => ({}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PLP = ({ settings }: Required<{ settings: PageSettings }>) => {
  const { partnerList } = usePartners();
  const { collectionList } = useCollections();
  const { categoryList } = useCategories();
  const profileState = useProfileState();
  const { q } = useQueryParams();
  const { configuration } = useConfiguration();
  const { AnalyticsTracker } = useAnalyticsTracker();

  useScreenshotPreviewElementEffect();

  useEffect(() => {
    AnalyticsTracker.trackIfItemSearch({ searchTerm: q });
  }, [q]);

  return (
    <MainContainer id="app-content">
      <CommonHead />
      <Head>
        <meta
          name="description"
          content="Full access to all the products and services discounts that you qualify for. Sign up today and save instantly."
        />
      </Head>
      <ProfileContext.Provider value={profileState}>
        <Layout
          isBackButtonIn={false}
          backButtonIn={undefined}
          partnerList={partnerList?.data}
          categories={categoryList?.data}
          collections={collectionList?.data}
          mobile={undefined}
          defaultExpanded={undefined}
          navigation={undefined}
          item={undefined}
          availableComponents={availableComponents}
          homePageLogo={configuration?.homePageLogoUrl}
          marketplaceName={configuration?.name}
        />
        <PoweredByBuiltfirstBanner />
      </ProfileContext.Provider>
    </MainContainer>
  );
};

export default PLP;
