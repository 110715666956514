import { useContext } from "react";
import { useQueryParams } from "../dataAccess/QueryParams";
import { EditorCtx } from "../wysiwyg/EditorCtx";
import {
  getAnalyticsData,
  setAnalyticsDataField,
} from "../dataAccess/storages/analytics";
import { trackEvent } from "../dataAccess/api/analytics";
import { jsonStringifySafe, simpleHash } from "../helpers/formatter";
import { Item } from "../dataAccess/api/item";
import { User } from "../dataAccess/api/user";

type AnalyticEventType =
  | "item_click"
  | "discount_click"
  | "discount_click_redirection"
  | "quote_request"
  | "query";

type AnalyticEventParams<T extends AnalyticEventType> = T extends "item_click"
  ? { listingId: string }
  : T extends "discount_click"
  ? { discountId: string; withItem: boolean }
  : T extends "discount_click_redirection"
  ? { oldDiscountId: string; newDiscountId: string }
  : T extends "quote_request"
  ? { listingId: string; discountId?: string }
  : T extends "query"
  ? { searchTerm: string }
  : never;

export interface AnalyticEvent {
  type: AnalyticEventType;
  info: AnalyticEventParams<AnalyticEventType>;
}

interface AnalyticsTracker {
  trackIfItemClick({ item, user }: { item?: Item; user?: User }): void;
  trackIfItemSearch({ searchTerm }: { searchTerm?: string }): void;
  trackIfQuoteRequest({ item }: { item?: Item }): void;
}

class AnalyticsTrackerImpl implements AnalyticsTracker {
  private lastEventTracked: AnalyticEvent | null = null;

  private queue: Promise<unknown>[] = [];

  private track<T extends AnalyticEventType>(
    type: T,
    info: AnalyticEventParams<T>,
  ) {
    const newEvent = { type, info };

    if (
      simpleHash(jsonStringifySafe(this.lastEventTracked) || "") !==
      simpleHash(jsonStringifySafe(newEvent) || "")
    ) {
      this.lastEventTracked = newEvent;

      const promiseToWaitForQueue = new Promise((resolve) => {
        if (this.queue.length) {
          Promise.all(this.queue).finally(() => {
            this.createTrackedAnalytic(newEvent).finally(() => resolve(true));
          });
        } else {
          this.createTrackedAnalytic(newEvent).finally(() => resolve(true));
        }
      });
      this.queue.push(promiseToWaitForQueue);

      setTimeout(() => {
        // To not track same analytic twice within 500ms range.
        // For example if two events with the type "search" and value "test2" are triggered, only 1 is queued
        this.lastEventTracked = null;
        setAnalyticsDataField("pageHasChanged", "false");
      }, 500);
    }
  }

  private checkPageHasChanged() {
    const { pageHasChanged } = getAnalyticsData();
    return pageHasChanged === "true";
  }

  private checkDealWasBlurred() {
    const { lastDealVisitedWasBlurred } = getAnalyticsData();
    const pageHasChanged = this.checkPageHasChanged();
    return lastDealVisitedWasBlurred === "true" && !pageHasChanged;
  }

  private checkDealWasUpgraded(item?: Item) {
    const { lastDealVisited } = getAnalyticsData();
    const dealWasBlurred = this.checkDealWasBlurred();
    const pageHasChanged = this.checkPageHasChanged();
    const dealHasChanged =
      !!(item?.deal?.id && item?.deal?.id !== lastDealVisited) ||
      (dealWasBlurred && !item?.blurredDeal?.id);
    return !pageHasChanged && dealHasChanged;
  }

  private trackIfDiscountClickOrRedirection({
    item,
    user,
  }: {
    item?: Item;
    user?: User;
  }) {
    const { lastDealVisited } = getAnalyticsData();
    const pageHasChanged = this.checkPageHasChanged();
    const dealWasUpgraded = this.checkDealWasUpgraded(item);
    const dealWasBlurred = this.checkDealWasBlurred();

    if (item?.deal?.id && (pageHasChanged || dealWasUpgraded)) {
      if (
        dealWasUpgraded &&
        !pageHasChanged &&
        !dealWasBlurred &&
        lastDealVisited
      ) {
        if (user?.id) {
          this.track("discount_click_redirection", {
            newDiscountId: item.deal.id,
            oldDiscountId: lastDealVisited,
          });
        }
      } else {
        this.track("discount_click", {
          discountId: item.deal.id,
          withItem: !dealWasBlurred,
        });
        setAnalyticsDataField("lastDealVisited", item?.deal?.id);
      }
    }
  }

  public trackIfItemClick({ item, user }: { item?: Item; user?: User }) {
    const pageHasChanged = this.checkPageHasChanged();
    const dealWasUpgraded = this.checkDealWasUpgraded(item);

    if (item?.id) {
      if (pageHasChanged || dealWasUpgraded) {
        if (item?.deal?.id) {
          this.trackIfDiscountClickOrRedirection({
            item,
            user,
          });
        } else {
          this.track("item_click", { listingId: item.id });
        }
      }
      if (item?.blurredDeal?.id) {
        setAnalyticsDataField("lastDealVisitedWasBlurred", "true");
      } else if (item?.deal?.id) {
        setAnalyticsDataField("lastDealVisitedWasBlurred", undefined);
      }
    }
  }

  public trackIfQuoteRequest({ item }: { item: Item }) {
    if (item?.id) {
      this.track("quote_request", {
        listingId: item.id,
        discountId: item.deal?.id,
      });
    }
  }

  public trackIfItemSearch({ searchTerm }: { searchTerm?: string }) {
    if (searchTerm && getAnalyticsData().lastQuery !== searchTerm) {
      this.track("query", { searchTerm });
    }
    setAnalyticsDataField("lastQuery", searchTerm || "");
  }

  private createTrackedAnalytic(event: AnalyticEvent) {
    return trackEvent(event).json();
  }
}

const analyticsTracker = new AnalyticsTrackerImpl();

export function useAnalyticsTracker() {
  const { isEditor } = useContext(EditorCtx);
  const { screenshot } = useQueryParams();
  const noopAnalyticsTracker: AnalyticsTracker = {
    trackIfItemClick: () => {},
    trackIfItemSearch: () => {},
    trackIfQuoteRequest: () => {},
  };

  const noAnalytics = isEditor || screenshot;
  return {
    AnalyticsTracker: noAnalytics ? noopAnalyticsTracker : analyticsTracker,
  };
}
