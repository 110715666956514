import { styled, Typography, useTheme } from "@mui/material";
import { FunctionComponent } from "react";
import EarningsBox from "../EarningsBox";
import TileLogo from "./TileLogo";
import {
  StandardHeader,
  Props as BaseTileHeaderProps,
  Title,
  tileLogoMargin,
} from "./StandardTileHeader";
import { getTileSpacing } from ".";
import { getHeaderItemPadding } from "./ElegantTileHeader";

const StackedHeader = styled(StandardHeader)(({ theme }) => ({
  flexWrap: "nowrap",
  alignItems: "flex-start",
  flexDirection: "column",
  paddingTop: theme.settings.tiles.padding
    ? theme.typography.pxToRem(theme.settings.tiles.padding)
    : "0.8rem",
  paddingLeft: theme.settings.tiles.padding
    ? theme.typography.pxToRem(theme.settings.tiles.padding)
    : "0.8rem",
  paddingRight: theme.settings.tiles.padding
    ? theme.typography.pxToRem(theme.settings.tiles.padding)
    : "0.8rem",
}));

export const StackedPartnerName = styled(Title)(({ theme }) => ({
  fontWeight: "normal",
  fontSize: theme.typography.body1.fontSize,
  marginBottom: theme.settings.tiles.spacing ? getTileSpacing(theme) : ".7rem",
  maxWidth: "100%",
}));

export const StackedDealName = styled(Title)(({ theme }) => ({
  flexBasis: "auto",
  maxWidth: "100%",
  color: theme.palette.primary.main,
  width: 0,
  minWidth: "100%",
  display: "-webkit-box",
  WebkitLineClamp: `${
    theme.settings.tiles.titlesMultipleRows ? `2 !important` : `1 !important`
  }`,
}));

export const StackedSavingAmount = styled(Typography)(({ theme }) => ({
  color: theme.palette.success.light,
  flexBasis: "auto",
  paddingLeft: getHeaderItemPadding(theme),
  paddingRight: getHeaderItemPadding(theme),
}));

const TileLogoContainer = styled("div")(() => ({
  marginBottom: tileLogoMargin,
}));

const StackedTileHeader: FunctionComponent<BaseTileHeaderProps> = ({
  item: { name, logoUrl = "", managerOrganization, deal },
}: BaseTileHeaderProps) => {
  const theme = useTheme();

  return (
    <StackedHeader>
      <TileLogoContainer>
        <TileLogo
          logoUrl={logoUrl}
          orgName={managerOrganization.name}
          width={50}
          height={50}
        />
      </TileLogoContainer>
      {theme.settings.tiles.titleContent.showVendorName && (
        <StackedPartnerName
          variant="h5"
          noWrap
          title={managerOrganization.name}
        >
          {managerOrganization.name}
        </StackedPartnerName>
      )}
      <StackedDealName variant="h5" title={name}>
        {theme.settings.tiles.titleContent.showListingName ? name : deal?.title}
      </StackedDealName>

      {theme.settings.tiles.titleContent.showSavingAmount &&
        !!deal?.averageTotalSavingsAmount && (
          <StackedSavingAmount>
            <EarningsBox
              color={theme.palette.tileOthersColor.main}
              averageTotalSavingsAmount={deal.averageTotalSavingsAmount}
              averageTotalSavingsPrefix={deal.averageTotalSavingsPrefix}
            />
          </StackedSavingAmount>
        )}
    </StackedHeader>
  );
};

export default StackedTileHeader;
