import { ReactNode, useCallback, useContext, useEffect, useRef } from "react";
import { Button, CSSInterpolation, styled } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useAddQueryParam } from "../dataAccess/QueryParams";
import { ComponentType } from "./JsonThemeObject";
import {
  EditorCtx,
  adminKey,
  currentComponentWrapperClassName,
} from "./EditorCtx";
import { FieldLabelFormatter } from "./builder/FieldLabelFormatter";

const currentComponentWrapperStyles: CSSInterpolation = {
  "& > [data-admin-preview]": {
    display: "flex",
  },
  padding: ".4rem", // special padding for mobile
  outlineColor: "#00f",
};

const ComponentWrapper = styled("div")<{ selectorEnabled?: boolean }>(
  ({ theme, selectorEnabled }) => ({
    display: "block !important",
    width: "100%",
    minHeight: 1,
    outlineOffset: -5,
    outlineWidth: "1px",
    outlineStyle: "dashed",
    outlineColor: "transparent",
    transition: ".2s",
    [`&.${currentComponentWrapperClassName}`]: {
      ...currentComponentWrapperStyles,
    },
    "&:hover": selectorEnabled
      ? {
          ...currentComponentWrapperStyles,
        }
      : {},
    [theme.breakpoints.up("md")]: {
      [`&.${currentComponentWrapperClassName}`]: {
        padding: theme.spacing(2),
      },
      "&:hover": selectorEnabled
        ? {
            padding: theme.spacing(2),
          }
        : {},
    },
  }),
);

const PreviewButton = styled(Button)(({ theme }) => ({
  display: "none",
  position: "relative",
  margin: theme.spacing(1),
  gap: theme.spacing(1),
  color: theme.palette.primary.main,
  zIndex: theme.zIndex.appBar,
  textTransform: "capitalize",
}));

interface Props {
  children: ReactNode;
  uniqueId: string;
  onEdit?: () => void;
}

export const EditorWrapper = ({ children, uniqueId, onEdit }: Props) => {
  const addQueryParam = useAddQueryParam();
  const { selectorEnabled, isEditor } = useContext(EditorCtx);
  const cloneStylesFrom: ComponentType[] = ["MoreInformation"];
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (
      cloneStylesFrom.find((componentName) => uniqueId.includes(componentName))
    ) {
      const [, child] = Array.from(ref.current?.childNodes || []);
      if (child instanceof Element && child.className) {
        ref.current?.classList.add(child.className);
      }
    }
  }, []);

  const onEditHandler = useCallback(() => {
    if (onEdit) {
      onEdit();
    } else {
      addQueryParam(adminKey, uniqueId);
    }
  }, [onEdit, addQueryParam, uniqueId]);

  return isEditor ? (
    <ComponentWrapper
      ref={ref}
      tabIndex={0}
      data-uniqueid={uniqueId}
      selectorEnabled={selectorEnabled}
    >
      <PreviewButton data-admin-preview size="small" onClick={onEditHandler}>
        <pre>
          <FieldLabelFormatter type="component">{uniqueId}</FieldLabelFormatter>
        </pre>
        <EditIcon />
      </PreviewButton>
      {children}
    </ComponentWrapper>
  ) : (
    <>{children}</>
  );
};
