import { FunctionComponent, PropsWithChildren } from "react";
import Link from "@mui/material/Link";
import { ButtonProps } from "@mui/material/Button";
import { ThemeButton } from "./ThemeComponents/ThemeButton";

interface Props {
  url: string;
}

const CTAButton: FunctionComponent<PropsWithChildren<Props & ButtonProps>> = ({
  url,
  children,
  variant,
}) => (
  <Link target="_blank" href={url}>
    <ThemeButton variant={variant || "contained"}>{children}</ThemeButton>
  </Link>
);

export default CTAButton;
