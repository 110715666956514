import { Button } from "@mui/material";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  managerAppMarketplacePagePath,
  useNavigateToManagerAppByJwt,
} from "../../helpers/navigation";
import { useConfiguration } from "../../dataAccess/api/configuration";
import {
  acceptInvitation,
  getManagerJwtAuth,
} from "../../dataAccess/api/managers";
import { EditorCtx } from "../../wysiwyg/EditorCtx";
import { useCurrentRoute } from "../../builder/mapping/page";
import { PickQueryParams, useQueryParams } from "../../dataAccess/QueryParams";
import { useUser } from "../../dataAccess/api/user";
import { getDomainNameFromUrl } from "../../helpers/environment";
import FooterBanner from "./FooterBanner";
import { ButtonProgress } from "./ButtonProgress";

interface PreviewParams {
  type: "quote" | "deal";
  onPreview: () => void;
  onRequest: () => void;
}

interface PreviewState {
  state: "requested";
}

interface PreviewBannerState {
  showPartnerBanner?: boolean;
  setShowPartnerBanner: (show: boolean) => void;
  showSellerBanner?: boolean;
  setShowSellerBanner: (show: boolean) => void;
  showInvitationBanner?: boolean;
  showPreviewRedeemBanner?: boolean;
  setShowPreviewRedeemBanner: (show: boolean) => void;
  setShowInvitationBanner: (show: boolean) => void;
  previewParams?: PreviewParams | null;
  setPreviewParams: (action: PreviewParams | null) => void;
  previewState?: PreviewState | null;
  setPreviewState: (action: PreviewState | null) => void;
}

export function usePreviewBannerState(): PreviewBannerState {
  const { user } = useUser();
  const currentRoute = useCurrentRoute<PickQueryParams<"id">>();
  const [showInvitationBanner, setShowInvitationBanner] = useState(
    user?.invitedBanner,
  );
  const [showSellerBanner, setShowSellerBanner] = useState(
    user?.sellerBanner && !user?.invitedBanner,
  );
  const [showPartnerBanner, setShowPartnerBanner] = useState(
    user?.partnerOwned && !user?.sellerBanner && currentRoute?.id !== "PDP",
  );
  const [showPreviewRedeemBanner, setShowPreviewRedeemBanner] = useState(false);
  const [previewState, setPreviewState] = useState<PreviewState | null>(null);
  const [previewParams, setPreviewParams] = useState<PreviewParams | null>(
    null,
  );

  return {
    showPartnerBanner,
    setShowPartnerBanner,
    showInvitationBanner,
    setShowInvitationBanner,
    showSellerBanner,
    setShowSellerBanner,
    showPreviewRedeemBanner,
    setShowPreviewRedeemBanner,
    previewParams,
    setPreviewParams,
    previewState,
    setPreviewState,
  };
}

const defaultPreviewBannerCtx: PreviewBannerState = {
  showPartnerBanner: false,
  setShowPartnerBanner: () => {},
  showSellerBanner: false,
  setShowSellerBanner: () => {},
  showInvitationBanner: false,
  setShowInvitationBanner: () => {},
  showPreviewRedeemBanner: false,
  setShowPreviewRedeemBanner: () => {},
  previewParams: null,
  setPreviewParams: () => {},
  previewState: null,
  setPreviewState: () => {},
};

export const PreviewBannerCtx = createContext({ ...defaultPreviewBannerCtx });

export const PreviewBannerCtxProvider = ({ children }: PropsWithChildren) => {
  const previewBannerState = usePreviewBannerState();
  return (
    <PreviewBannerCtx.Provider value={previewBannerState}>
      {children}
    </PreviewBannerCtx.Provider>
  );
};

// eslint-disable-next-line react/no-multi-comp
const PreviewBanner = () => {
  const { screenshot } = useQueryParams();
  const { configuration } = useConfiguration();
  const {
    showInvitationBanner,
    setShowInvitationBanner,
    showPartnerBanner,
    setShowPartnerBanner,
    showSellerBanner,
    setShowSellerBanner,
    showPreviewRedeemBanner,
    setShowPreviewRedeemBanner,
    previewParams,
    setPreviewParams,
  } = useContext(PreviewBannerCtx);

  const [isAcceptInvitationLoading, setIsAcceptInvitationLoading] =
    useState(false);
  const [isManagerJwtLoading, setIsManagerJwtLoading] = useState(false);
  const { navigateToManagerAppByJwt } = useNavigateToManagerAppByJwt(
    setIsManagerJwtLoading,
  );
  const { navigateToManagerAppByJwt: acceptManagerInvitation } =
    useNavigateToManagerAppByJwt(setIsAcceptInvitationLoading);
  const managerAppMarketplaceDetailsPagePath = configuration?.id
    ? `${managerAppMarketplacePagePath}/${configuration?.id}`
    : managerAppMarketplacePagePath;

  useEffect(() => {
    if (previewParams?.type) {
      setShowPreviewRedeemBanner(false);
    }
  }, [previewParams?.type]);

  const onAcceptInvitationClick = useCallback(
    () =>
      acceptManagerInvitation(
        acceptInvitation,
        managerAppMarketplaceDetailsPagePath,
      ),
    [acceptManagerInvitation, managerAppMarketplaceDetailsPagePath],
  );

  const onManageProductsClick = useCallback(
    () =>
      navigateToManagerAppByJwt(
        getManagerJwtAuth,
        managerAppMarketplaceDetailsPagePath,
      ),
    [managerAppMarketplaceDetailsPagePath, navigateToManagerAppByJwt],
  );

  const showRedeemBanner = previewParams?.type;

  const { isEditor } = useContext(EditorCtx);

  if (isEditor || screenshot === "true") {
    return <></>;
  }

  if (showPreviewRedeemBanner) {
    return (
      <FooterBanner onClose={() => setShowPreviewRedeemBanner(false)}>
        No analytics have been tracked and the seller has not been notified.
        Refresh to {previewParams?.type === "quote" ? "request" : "redeem"}.
      </FooterBanner>
    );
  }

  if (showRedeemBanner) {
    return (
      <FooterBanner
        onClose={() => setPreviewParams(null)}
        renderActions={() => (
          <>
            <Button
              variant="outlined"
              onClick={() => {
                setPreviewParams(null);
                setShowPreviewRedeemBanner(true);
                if (previewParams?.onPreview) {
                  previewParams.onPreview();
                }
              }}
            >
              Preview
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setPreviewParams(null);
                if (previewParams?.onRequest) {
                  previewParams.onRequest();
                }
              }}
            >
              {previewParams?.type === "quote" ? "Request" : "Redeem"}
            </Button>
          </>
        )}
      >
        {previewParams?.type === "quote"
          ? "Do you want to preview the request flow or request the quote?"
          : "Do you want to preview the redemption flow or redeem the deal?"}
      </FooterBanner>
    );
  }

  if (showInvitationBanner) {
    return (
      <FooterBanner
        onClose={() => setShowInvitationBanner(false)}
        renderActions={() => (
          <Button
            startIcon={isAcceptInvitationLoading && <ButtonProgress />}
            disabled={isAcceptInvitationLoading}
            onClick={onAcceptInvitationClick}
            variant="outlined"
          >
            Accept Invitation
          </Button>
        )}
      >
        Join your team by accepting the invite.
      </FooterBanner>
    );
  }

  if (showPartnerBanner) {
    return (
      <FooterBanner
        onClose={() => setShowPartnerBanner(false)}
        renderActions={() => (
          <Button
            startIcon={isManagerJwtLoading && <ButtonProgress />}
            disabled={isManagerJwtLoading}
            onClick={onManageProductsClick}
            variant="outlined"
          >
            Manage Your Marketplace
          </Button>
        )}
      >
        This is a preview of Builtfirst Marketplace.
      </FooterBanner>
    );
  }

  if (showSellerBanner) {
    return (
      <FooterBanner
        onClose={() => setShowSellerBanner(false)}
        renderActions={() => (
          <Button onClick={onManageProductsClick} variant="outlined">
            Manage Your Products
          </Button>
        )}
      >
        You are viewing {getDomainNameFromUrl()}&apos;s Marketplace as a buyer.
        Click to manage your products on Builtfirst.
      </FooterBanner>
    );
  }

  return <></>;
};

export default PreviewBanner;
