import { FunctionComponent } from "react";
import CTAButtonUI from "../ui/CTAButton";
import { PublicComponentProps } from "./PublicComponentProps";

export interface Settings {
  text: string;
  url: string;
}

const CTAButton: FunctionComponent<PublicComponentProps<Settings>> = ({
  settings: { url, text } = { url: "", text: "" },
}) => <CTAButtonUI url={url}>{text}</CTAButtonUI>;

export default CTAButton;
