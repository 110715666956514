import { FunctionComponent } from "react";
import CustomHTML from "../ui/CustomHTML";
import { PublicComponentProps } from "./PublicComponentProps";

interface Settings {
  html: string;
}

const BannerWithTextCustomHTML: FunctionComponent<
  PublicComponentProps<Settings>
> = ({ settings: { html } }) => <CustomHTML html={html} />;

export default BannerWithTextCustomHTML;
