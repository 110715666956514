import { FunctionComponent, ReactNode } from "react";
import { styled, Typography as TypographyMUI } from "@mui/material";
import type { Size } from "../../types/theme";
import { HeaderSizes } from "../../builder/useFontsLayout";

export interface Props {
  children: ReactNode;
  size: Size["size"];
}

const Typography = styled(TypographyMUI)<{ size: Size["size"] }>(
  ({ theme, size }) => ({
    fontSize: theme.typography[HeaderSizes[size || "medium"]].fontSize,
  }),
);

const Header: FunctionComponent<Props> = ({ children, size }) => (
  <Typography variant="h2" size={size}>
    {children}
  </Typography>
);

export default Header;
