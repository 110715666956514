/* eslint-disable react/no-multi-comp */
import { FunctionComponent, ReactNode, useMemo } from "react";
import { useComponentsToForceExclude } from "../app/componentHooks";
import type { ThemeComponent, ComponentList } from "../wysiwyg/JsonThemeObject";
import { PublicComponentProps } from "../components/public/PublicComponentProps";
import { ServerError } from "../dataAccess/ServerError";
import { useConfiguration } from "../dataAccess/api/configuration";
import { useCurrentRoute } from "./mapping/page";
import type { SettingsConfiguration } from "./Theme";
import { EditorWrapper } from "../wysiwyg/EditorWrapper";

import PreviewBanner, {
  PreviewBannerCtxProvider,
} from "../components/ui/PreviewBanner";

export interface LayoutComponent<SettingsType> {
  component: ReactNode;
  settings: SettingsType;
}

export type SiblingsComponentsType<T> = Record<
  keyof T,
  Omit<LayoutComponent<SettingsConfiguration>, "component">
>;
// TODO: add the right type on the next line
type PublicComponentType = FunctionComponent<PublicComponentProps<any>>;

function renderComponents<T extends ComponentList>(
  components: ThemeComponent[],
  props: any, // TODO: change type to PublicComponentProps requires to change public components Props to extend PublicComponentProps
  availableComponents: T,
  parentSettings?: SettingsConfiguration,
) {
  const renderedComponents = (components || []).map(
    (
      { type, components: subComponents, settings, uniqueId },
      index,
    ): {
      type: keyof T | null;
      component: ReactNode;
      settings: SettingsConfiguration;
    } => {
      const componentProps = {
        children: null,
        settings,
        ...props,
      };
      // TODO: remove "any" in the next line
      const Component = (props: PublicComponentProps<any>) => {
        const AvailableComponent: PublicComponentType =
          availableComponents[type];
        if (!AvailableComponent) {
          console.error(
            `${String(type)} Component is not available for this page`,
          );
          return <></>;
        }
        return <AvailableComponent {...props} />;
      };

      if (!Component) {
        return {
          type: null,
          settings,
          component: (
            <>Error: Component not found {type}, please contact support</>
          ),
        };
      }

      const mappedSiblingsComponents: Partial<SiblingsComponentsType<T>> = {};
      components.forEach(({ type: siblingType, settings }) => {
        if (siblingType && siblingType !== type) {
          mappedSiblingsComponents[siblingType] = {
            settings,
          };
        }
      });

      if (subComponents) {
        const renderedSubComponents = renderComponents(
          subComponents,
          props,
          availableComponents,
          settings,
        );

        const mappedSubComponents: Partial<
          Record<keyof T, LayoutComponent<SettingsConfiguration>>
        > = {};
        renderedSubComponents.forEach(({ component, type, settings }) => {
          if (type) {
            mappedSubComponents[type] = {
              component,
              settings,
            };
          }
        });
        const key = `${String(type)}${index}`;
        return {
          type,
          settings,
          component: (
            <EditorWrapper uniqueId={uniqueId || ""} key={key}>
              <Component
                key={key}
                {...componentProps}
                index={index}
                subComponents={mappedSubComponents}
                type={type}
                siblingsComponents={mappedSiblingsComponents}
                parentComponent={{
                  type,
                  settings: parentSettings,
                }}
              />
            </EditorWrapper>
          ),
        };
      }

      const key = `${String(type)}${index}`;
      return {
        type,
        settings,
        component: (
          <EditorWrapper uniqueId={uniqueId || ""} key={key}>
            <Component
              key={key}
              {...componentProps}
              index={index}
              type={type}
              siblingsComponents={mappedSiblingsComponents}
              parentComponent={{
                type,
                settings: parentSettings,
              }}
            />
          </EditorWrapper>
        ),
      };
    },
  );
  return renderedComponents;
}
// TODO: remove all the "any" types
type AvailableComponents<T> = {
  [K in keyof T]: T[K] extends FunctionComponent<infer P>
    ? P extends PublicComponentProps<any, any, any, any>
      ? T[K]
      : never
    : never;
};
type ExcludedAttributes =
  | "siblingsComponents"
  | "settings"
  | "subComponents"
  | "parentComponent"
  | "index"
  | "type";
type AvailableComponentsProps<T> = {
  [K in keyof T]: T[K] extends FunctionComponent<infer X>
    ? Exclude<keyof X, ExcludedAttributes>
    : never;
};

type AvailableComponentsPropsValues<T> = {
  [K in keyof T]: T[K] extends FunctionComponent<infer X> ? X : never;
};

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (
  k: infer I,
) => void
  ? I
  : never;

export type LayoutProps<X> = {
  availableComponents: AvailableComponents<X>;
} & {
  [K in AvailableComponentsProps<X>[keyof X]]: UnionToIntersection<
    AvailableComponentsPropsValues<X>[keyof X]
  >[K];
};

export function Layout<
  Props extends LayoutProps<Props["availableComponents"]>,
>({ ...props }: Props) {
  const route = useCurrentRoute();
  const { configuration } = useConfiguration();
  const { componentsToForceExclude } = useComponentsToForceExclude();
  const pageComponents = useMemo(() => {
    function filterComponent({ type }: ThemeComponent) {
      return !componentsToForceExclude.includes(type);
    }
    if (!(configuration instanceof ServerError)) {
      const pageConfiguration =
        configuration?.publishedTemplate.properties.pages.find(
          ({ id }: { id: string }) => id === route?.id,
        );
      return pageConfiguration?.components
        ?.filter(filterComponent)
        ?.map((component) => {
          if (component.components?.length) {
            component.components = component.components.filter(filterComponent);
          }
          return component;
        });
    }
  }, [configuration, route, componentsToForceExclude]);
  const { availableComponents, ...componentProps } = props;
  const components = renderComponents(
    pageComponents || [],
    componentProps,
    availableComponents as ComponentList,
  );

  if (pageComponents && components) {
    return (
      <PreviewBannerCtxProvider>
        {components.map(({ component }) => component)}
        <PreviewBanner />
      </PreviewBannerCtxProvider>
    );
  }
  return <>Error loading community configuration</>;
}

export default Layout;
