import { FunctionComponent, useCallback, useContext, useState } from "react";
import { EditorCtx } from "../../wysiwyg/EditorCtx";
import { PublicComponentProps } from "./PublicComponentProps";
import { ThemeButton } from "../ui/ThemeComponents/ThemeButton";
import { useNavigateToManagerAppByJwt } from "../../helpers/navigation";
import { ButtonProgress } from "../ui/ButtonProgress";
import { getManagerJwtAuthAndBecomeSeller } from "../../dataAccess/api/managers";

export interface Settings {
  text: string;
}

const ManageProfileSignUpButton: FunctionComponent<
  PublicComponentProps<Settings>
> = ({ settings: { text } }) => {
  const [isManagerJwtLoading, setIsManagerJwtLoading] = useState(false);
  const { navigateToManagerAppByJwt } = useNavigateToManagerAppByJwt(
    setIsManagerJwtLoading,
  );
  const { isEditor, messages, setCurrentMessage } = useContext(EditorCtx);

  const becomeManageProfile = useCallback(() => {
    if (isEditor) {
      setCurrentMessage(messages.previewAlert);
      return;
    }
    navigateToManagerAppByJwt(
      getManagerJwtAuthAndBecomeSeller,
      "onboarding?returl=seller/home",
      { target: "_blank" },
    );
  }, [
    isEditor,
    messages.previewAlert,
    navigateToManagerAppByJwt,
    setCurrentMessage,
  ]);

  return (
    <ThemeButton
      variant="contained"
      startIcon={isManagerJwtLoading && <ButtonProgress />}
      disabled={isManagerJwtLoading}
      onClick={becomeManageProfile}
    >
      {text}
    </ThemeButton>
  );
};

export default ManageProfileSignUpButton;
