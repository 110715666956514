import { FunctionComponent, useContext } from "react";
import { Typography, getLuminance, styled } from "@mui/material";
import { useConfiguration } from "../../dataAccess/api/configuration";
import { EditorWrapper } from "../../wysiwyg/EditorWrapper";
import { EditorCtx } from "../../wysiwyg/EditorCtx";
import BuilfirstLogo from "../../assets/svg/builtfirst-logo.svg";

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(3),
}));

const Content = styled("a")<{ paletteVariant: "dark" | "light" }>(
  ({ paletteVariant }) => ({
    display: "flex",
    textDecoration: "none",
    outline: "none",
    flexDirection: "column",
    gap: 3,
    "& .MuiTypography-root": {
      color: paletteVariant === "dark" ? "#333" : "#fff",
    },
    "& svg": {
      color: paletteVariant === "dark" ? "#000" : "#fff",
    },
  }),
);

const Text = styled(Typography)(() => ({
  fontSize: 10,
  fontFamily: "Arial",
  opacity: 0.5,
}));

const PoweredByBuiltfirstBanner: FunctionComponent = () => {
  const { configuration } = useConfiguration();
  const contrastColor =
    configuration?.publishedTemplate?.properties?.settings?.colors?.pages
      ?.backgroundColor || "#fff";
  const { messages, setCurrentMessage } = useContext(EditorCtx);

  if (!configuration?.brandingSetting?.poweredByBuiltfirstLabel) {
    return <></>;
  }

  return (
    <EditorWrapper
      uniqueId="Powered By Builtfirst Label"
      onEdit={() => setCurrentMessage(messages.editBranding)}
    >
      <Container>
        <Content
          paletteVariant={getLuminance(contrastColor) > 0.5 ? "dark" : "light"}
          target="_blank"
          href="https://builtfirst.com"
        >
          <Text>Powered by</Text>
          <BuilfirstLogo />
        </Content>
      </Container>
    </EditorWrapper>
  );
};

export default PoweredByBuiltfirstBanner;
