import Profile from "../../../components/public/Profile";
import { MediaSetting } from "../../../builder/themeOptionsBuilder";
import { ComponentDefaults } from "../../ThemeSchema";

type Component = typeof Profile;

const subcomponents: ComponentDefaults<Component>["subcomponents"] = [];

const settings: ComponentDefaults<Component>["settings"] = {
  desktopBottomSpacing: MediaSetting.top,
  desktopTopSpacing: MediaSetting.top,
  layout: {
    contained: false,
    backgroundImage: "",
    backgroundImageMobile: "",
    minHeight: 100,
    layoutAlignment: "right",
    textColor: "",
    backgroundImageMode: "tile",
    backgroundColor: "",
    tileRepeat: "repeatX",
  },
};

const schema: ComponentDefaults<Component>["schema"] = {
  type: "object",
  "x-metadata": {
    title: "Profile",
    description:
      "Display user name and avatar and allow users to sign in or sign up",
    preventAdd: "useMarketplaceNoAccounts",
    preventRemove: "useMarketplaceAccountsWithoutJWTOnSpecificPages",
  },
  properties: {
    desktopTopSpacing: {
      type: "string",
      "x-metadata": {
        title: "Desktop Top Spacing",
        description: "The top spacing for desktop",
      },
    },
    desktopBottomSpacing: {
      type: "string",
      "x-metadata": {
        title: "Desktop Bottom Spacing",
        description: "The bottom spacing for desktop",
      },
    },
    layout: {
      type: "object",
      properties: {
        contained: {
          type: "boolean",
          "x-metadata": {
            title: "Contained",
            description: "Determines if the component should be contained",
          },
        },
        backgroundColor: {
          type: "string",
          "x-metadata": {
            title: "Background Color",
            description: "The color of the background",
          },
        },
        textColor: {
          type: "string",
          "x-metadata": {
            title: "Text Color",
            description: "The color of the text",
          },
        },
        backgroundImage: {
          type: "string",
          "x-metadata": {
            title: "Background Image (desktop)",
            description: "This image will be used as background in desktop",
            devices: ["desktop"],
          },
        },
        backgroundImageMobile: {
          type: "string",
          "x-metadata": {
            title: "Background Image",
            description: "This image will be used as background in mobile",
            devices: ["mobile", "tablet"],
          },
        },
        backgroundImageMode: {
          type: "string",
          enum: ["crop", "fill", "fit", "tile"],
          "x-metadata": {
            title: "Background Image Mode",
            description: "The mode for displaying the background image",
          },
        },
        layoutAlignment: {
          type: "string",
          enum: ["left", "center", "right"],
          "x-metadata": {
            title: "Layout Alignment",
            description: "The alignment of the layout",
          },
        },
        minHeight: {
          type: "number",
          "x-metadata": {
            title: "Minimum Height",
            description: "The minimum height of the component",
          },
        },
        tileRepeat: {
          type: "string",
          enum: ["repeatX", "repeatY", "both"],
          "x-metadata": {
            title: "Background Repeat",
            description: "The repeat mode for the tile",
          },
        },
      },
    },
  },
};

export const profile = { subcomponents, settings, schema };
