import React, { FunctionComponent } from "react";
import { styled, Box, alpha } from "@mui/material";
import Image from "next/legacy/image";
import { useShadows } from "../../../builder/Theme";

interface Props {
  logoUrl?: string;
  orgName?: string;
  width: number;
  height: number;
}

const ConfiguredImageContainer = styled(Box)<{
  width: Props["width"];
  height: Props["height"];
}>(({ theme, width, height }) => {
  const border = `${theme.settings.logos.border.thickness}px solid ${alpha(
    theme.palette.inputsUnderline.main,
    theme.settings.logos.border.opacity / 100,
  )}`;
  const boxShadow = useShadows(theme.settings.logos.shadows);
  return {
    border,
    boxShadow,
    background: theme.palette.tileBackground.default,
    width,
    height,
    borderRadius: `${theme.settings.logos.border.radius}%`,
    overflow: "hidden",
  };
});

const TileLogo: FunctionComponent<Props> = ({
  logoUrl = "",
  orgName = "",
  width,
  height,
}) =>
  logoUrl ? (
    <ConfiguredImageContainer width={width} height={height}>
      <Image
        alt={`${orgName} logo`}
        src={logoUrl}
        objectFit="contain"
        width={width}
        height={height}
      />
    </ConfiguredImageContainer>
  ) : (
    <></>
  );

export default TileLogo;
